html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  /* background-color: #F5F8FA !important; */
}

body::-webkit-scrollbar {
  display: none;
}

ol {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Background color start */
.bg-light-blue {
  background-color: #f5f5f5 !important;
}

.d-nav-none {
  display: none !important;
}

.footer {
  background-color: #20409a;
  color: white;
}

.bg-blue {
  background-color: #162080;
}

.fa-brands {
  color: white;
}

.fa-brands:hover {
  opacity: 0.6;
}

/* Background color End */

/* border radius */

.br-10 {
  border-radius: 10px;
}

/* border radius End*/

/* Text Color Start */

.text-blue {
  color: #20409a;
}

.text-logo {
  color: #162080;
}

/* Text Color End */

/* Font sizes Start */

.s-12 {
  font-size: 12px;
}

.s-14 {
  font-size: 14px;
}

.s-16 {
  font-size: 16px;
}

.s-18 {
  font-size: 18px;
}

.s-20 {
  font-size: 20px;
}

.s-22 {
  font-size: 22px;
}

.s-24 {
  font-size: 24px;
}

.s-26 {
  font-size: 26px;
}

.s-28 {
  font-size: 28px;
}

.s-29 {
  font-size: 29px;
}

.s-30 {
  font-size: 30px;
}

.s-32 {
  font-size: 32px;
}

.s-34 {
  font-size: 34px;
}

.s-48 {
  font-size: 48px;
}

/* Font sizes End */

/* Font Weight Start */

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

.scroll-top {
  width: 100px;
}

.zindex {
  z-index: 9999999999999;
}

/* Font Weight End */

/* hover effect start */
.card-shadow:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  opacity: 0.9;
  transition: 0.5s;
}

.shimmer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* opacity: 0.9; */
  /* transition: 0.5s; */
}

.shadow-effect {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* hover effect end */

.l-spacing {
  letter-spacing: 1px;
}

.btn-nav {
  background-color: #f5f5f5;
  color: #162080;
  border-radius: 5px;
  font-weight: 700;
}

.bg-dark-blue {
  background-color: #162080;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example::-webkit-scrollbar {
  display: none;
}

.steps-list li {
  background: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  counter-increment: mw-counter;
  font-size: 16px;
  margin: 0 0 1.5rem 1em;
  padding: 10px 15px 10px 35px;
  position: relative;
}

.steps-list li::before {
  background: #204099;
  border-radius: 40px;
  box-shadow: 0 0 3px rgb(0 0 0 / 40%), 0 0 0 5px #f5f8fa,
    5px 0 2px rgb(0 0 0 / 60%);
  color: #fff;
  content: counter(mw-counter);
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  left: -1.2em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.col-container-main {
  display: table;
  width: 100%;
}

.col-main {
  display: table-cell;
  padding: 10px;
}

/* .form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url(./Assets/Images/angle-down-solid.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
} */

/* Male Front */

.mfs0 {
  fill: url(#mfg1);
}

.mfs1 {
  fill: url(#mfg2);
}

.mfs2 {
  fill: url(#mfg3);
}

.mfs3 {
  fill: url(#mfg4);
}

.mfs4 {
  fill: url(#mfg5);
}

.mfs5 {
  fill: url(#mfg6);
}

.mfs6 {
  fill: url(#mfg7);
}

.mfs7 {
  fill: url(#mfg8);
}

.mfs8 {
  fill: url(#mfg9);
}

.mfs9 {
  fill: url(#mfg10);
}

.mfs10 {
  fill: url(#mfg11);
}

.mfs11 {
  fill: url(#mfg12);
}

.mfs12 {
  fill: url(#mfg13);
}

.mfs13 {
  fill: url(#mfg14);
}

.mfs14 {
  fill: url(#mfg15);
}

.mfs15 {
  fill: url(#mfg16);
}

.mfs16 {
  fill: url(#mfg17);
}

.mfs17 {
  fill: url(#mfg18);
}

.mfs18 {
  fill: url(#mfg19);
}

.mfs19 {
  fill: url(#mfg20);
}

.mfs20 {
  fill: url(#mfg21);
}

.mfs21 {
  fill: url(#mfg22);
}

.mfs22 {
  fill: url(#mfg23);
}

.mfs23 {
  fill: url(#mfg24);
}

.mfs24 {
  fill: url(#mfg25);
}

.mfs25 {
  fill: url(#mfg26);
}

.mfs26 {
  fill: url(#mfg27);
}

.mfs27 {
  fill: url(#mfg28);
}

.mfs28 {
  fill: url(#mfg29);
}

.mfs29 {
  fill: url(#mfg30);
}

.mfs30 {
  fill: url(#mfg31);
}

/* Male bcak */
.mbs0 {
  fill: url(#mbg1);
}

.mbs1 {
  fill: url(#mbg2);
}

.mbs2 {
  fill: url(#mbg3);
}

.mbs3 {
  fill: url(#mbg4);
}

.mbs4 {
  fill: url(#mbg5);
}

.mbs5 {
  fill: url(#mbg6);
}

.mbs6 {
  fill: url(#mbg7);
}

.mbs7 {
  fill: url(#mbg8);
}

.mbs8 {
  fill: url(#mbg9);
}

.mbs9 {
  fill: url(#mbg10);
}

.mbs10 {
  fill: url(#mbg11);
}

.mbs11 {
  fill: url(#mbg12);
}

.mbs12 {
  fill: url(#mbg13);
}

.mbs13 {
  fill: url(#mbg14);
}

.mbs14 {
  fill: url(#mbg15);
}

.mbs15 {
  fill: url(#mbg16);
}

.mbs16 {
  fill: url(#mbg17);
}

.mbs17 {
  fill: url(#mbg18);
}

.mbs18 {
  fill: url(#mbg19);
}

.mbs19 {
  fill: url(#mbg20);
}

.mbs20 {
  fill: url(#mbg21);
}

.mbs21 {
  fill: url(#mbg22);
}

.mbs22 {
  fill: url(#mbg23);
}

.mbs23 {
  fill: url(#mbg24);
}

.mbs24 {
  fill: url(#mbg25);
}

.mbs25 {
  fill: url(#mbg26);
}

.mbs26 {
  fill: url(#mbg27);
}

.mbs27 {
  fill: url(#mbg28);
}

.mbs28 {
  fill: url(#mbg29);
}

.mbs29 {
  fill: url(#mbg30);
}

.mbs30 {
  fill: url(#mbg31);
}

.mbs31 {
  fill: url(#mbg32);
}

.mbs32 {
  fill: url(#mbg33);
}

.mbs33 {
  fill: url(#mbg34);
}

.mbs34 {
  fill: url(#mbg35);
}

.mbs35 {
  fill: url(#mbg36);
}

.mbs36 {
  fill: url(#mbg37);
}

.mbs37 {
  fill: url(#mbg38);
}

.mbs38 {
  fill: url(#mbg39);
}

.mbs39 {
  fill: url(#mbg40);
}

.mbs40 {
  fill: url(#mbg41);
}

.mbs41 {
  fill: url(#mbg42);
}

.mbs42 {
  fill: url(#mbg43);
}

.mbs43 {
  fill: url(#mbg44);
}

.mbs44 {
  fill: url(#mbg45);
}

.mbs45 {
  fill: url(#mbg46);
}

.mbs46 {
  fill: url(#mbg47);
}

.mbs47 {
  fill: url(#mbg48);
}

.mbs48 {
  fill: url(#mbg49);
}

.mbs49 {
  fill: url(#mbg50);
}

.mbs50 {
  fill: url(#mbg51);
}

.mbs51 {
  fill: url(#mbg52);
}

.mbs52 {
  fill: url(#mbg53);
}

.mbs53 {
  fill: url(#mbg54);
}

.mbs54 {
  fill: url(#mbg55);
}

.mbs55 {
  fill: url(#mbg56);
}

.mbs56 {
  fill: url(#mbg57);
}

.mbs57 {
  fill: url(#mbg58);
}

.mbs58 {
  fill: url(#mbg59);
}

.mbs59 {
  fill: url(#mbg60);
}

.mbs60 {
  fill: url(#mbg61);
}

.mbs61 {
  fill: url(#mbg62);
}

.mbs62 {
  fill: url(#mbg63);
}

.body-map__muscle {
  filter: opacity(0);
  -webkit-filter: opacity(0);
  -webkit-opacity: 0;
  /*visibility: hidden;
  */
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.body-map__muscle:hover {
  filter: opacity(1);
  /*visibility: visible;
  */
  transition: all 0.5s ease-in-out;
  -webkit-filter: opacity(1);
  -webkit-opacity: 1;
}

.body-map__muscle:focus {
  filter: inherit;
  outline: none !important;
  /* visibility: visible; */
  /* transition: all 0.5s ease-in-out; */
}

.bs0 {
  fill: url(#g211);
}

.bs1 {
  fill: url(#g212);
}

.bs2 {
  fill: url(#g213);
}

.bs3 {
  fill: url(#g214);
}

.bs4 {
  fill: url(#g215);
}

.bs5 {
  fill: url(#g216);
}

.bs6 {
  fill: url(#g217);
}

.bs7 {
  fill: url(#g218);
}

.bs8 {
  fill: url(#g219);
}

.bs9 {
  fill: url(#g110);
}

.bs10 {
  fill: url(#g111);
}

.bs11 {
  fill: url(#g112);
}

.bs12 {
  fill: url(#g113);
}

.bs13 {
  fill: url(#g114);
}

.bs14 {
  fill: url(#g115);
}

.bs15 {
  fill: url(#g116);
}

.bs16 {
  fill: url(#g117);
}

.bs17 {
  fill: url(#g118);
}

.bs18 {
  fill: url(#g119);
}

.bs19 {
  fill: url(#g120);
}

.bs20 {
  fill: url(#g121);
}

.bs21 {
  fill: url(#g122);
}

.bs22 {
  fill: url(#g123);
}

.bs23 {
  fill: url(#g124);
}

.bs24 {
  fill: url(#g125);
}

.bs25 {
  fill: url(#g126);
}

.bs26 {
  fill: url(#g127);
}

.bs27 {
  fill: url(#g128);
}

.bs28 {
  fill: url(#g129);
}

.bs29 {
  fill: url(#g130);
}

.bs30 {
  fill: url(#g131);
}

.bs31 {
  fill: url(#g132);
}

.bs32 {
  fill: url(#g133);
}

.bs33 {
  fill: url(#g134);
}

.bs34 {
  fill: url(#g135);
}

.bs35 {
  fill: url(#g136);
}

.bs36 {
  fill: url(#g137);
}

.bs37 {
  fill: url(#g138);
}

.bs38 {
  fill: url(#g139);
}

.bs39 {
  fill: url(#g140);
}

.bs40 {
  fill: url(#g141);
}

.bs41 {
  fill: url(#g142);
}

.bs42 {
  fill: url(#g143);
}

.bs43 {
  fill: url(#g144);
}

.bs44 {
  fill: url(#g145);
}

.bs45 {
  fill: url(#g146);
}

.bs46 {
  fill: url(#g147);
}

.bs47 {
  fill: url(#g148);
}

.bs48 {
  fill: url(#g149);
}

.bs49 {
  fill: url(#g150);
}

.bs50 {
  fill: url(#g151);
}

.bs51 {
  fill: url(#g152);
}

.bs52 {
  fill: url(#g153);
}

.bs53 {
  fill: url(#g154);
}

.bs54 {
  fill: url(#g155);
}

.bs55 {
  fill: url(#g156);
}

.bs56 {
  fill: url(#g157);
}

.bs57 {
  fill: url(#g158);
}

.bs58 {
  fill: url(#g159);
}

.bs59 {
  fill: url(#g160);
}

.bs60 {
  fill: url(#g161);
}

.bs61 {
  fill: url(#g162);
}

.bs62 {
  fill: url(#g163);
}

/* Female Front*/

.ffs0 {
  fill: url(#ffg1);
}

.ffs0 {
  fill: url(#ffg1);
}

.ffs1 {
  fill: url(#ffg2);
}

.ffs2 {
  fill: url(#ffg3);
}

.ffs3 {
  fill: url(#ffg4);
}

.ffs4 {
  fill: url(#ffg5);
}

.ffs5 {
  fill: url(#ffg6);
}

.ffs6 {
  fill: url(#ffg7);
}

.ffs7 {
  fill: url(#ffg8);
}

.ffs8 {
  fill: url(#ffg9);
}

.ffs9 {
  fill: url(#ffg10);
}

.ffs10 {
  fill: url(#ffg11);
}

.ffs11 {
  fill: url(#ffg12);
}

.ffs12 {
  fill: url(#ffg13);
}

.ffs13 {
  fill: url(#ffg14);
}

.ffs14 {
  fill: url(#ffg15);
}

.ffs15 {
  fill: url(#ffg16);
}

.ffs16 {
  fill: url(#ffg17);
}

.ffs17 {
  fill: url(#ffg18);
}

.ffs18 {
  fill: url(#ffg19);
}

.ffs19 {
  fill: url(#ffg20);
}

.ffs20 {
  fill: url(#ffg21);
}

.ffs21 {
  fill: url(#ffg22);
}

.ffs22 {
  fill: url(#ffg23);
}

.ffs23 {
  fill: url(#ffg24);
}

.ffs24 {
  fill: url(#ffg25);
}

.ffs25 {
  fill: url(#ffg26);
}

.ffs26 {
  fill: url(#ffg27);
}

.ffs27 {
  fill: url(#ffg28);
}

.ffs28 {
  fill: url(#ffg29);
}

.ffs29 {
  fill: url(#ffg30);
}

.ffs30 {
  fill: url(#ffg31);
}

.ffs31 {
  fill: url(#ffg32);
}

.ffs32 {
  fill: url(#ffg33);
}

.ffs33 {
  fill: url(#ffg34);
}

.ffs34 {
  fill: url(#ffg35);
}

.ffs35 {
  fill: url(#ffg36);
}

.ffs36 {
  fill: url(#ffg37);
}

.ffs37 {
  fill: url(#ffg38);
}

.ffs38 {
  fill: url(#ffg39);
}

.ffs39 {
  fill: url(#ffg40);
}

.ffs40 {
  fill: url(#ffg41);
}

.ffs41 {
  fill: url(#ffg42);
}

.ffs42 {
  fill: url(#ffg43);
}

.ffs43 {
  fill: url(#ffg44);
}

.ffs44 {
  fill: url(#ffg45);
}

.ffs45 {
  fill: url(#ffg46);
}

.ffs46 {
  fill: url(#ffg47);
}

.ffs47 {
  fill: url(#ffg48);
}

.ffs48 {
  fill: url(#ffg49);
}

.ffs49 {
  fill: url(#ffg50);
}

.ffs50 {
  fill: url(#ffg51);
}

.ffs51 {
  fill: url(#ffg52);
}

.ffs52 {
  fill: url(#ffg53);
}

.ffs53 {
  fill: url(#ffg54);
}

.ffs54 {
  fill: url(#ffg55);
}

.ffs55 {
  fill: url(#ffg56);
}

.ffs56 {
  fill: url(#ffg57);
}

.ffs57 {
  fill: url(#ffg58);
}

.ffs58 {
  fill: url(#ffg59);
}

.ffs59 {
  fill: url(#ffg60);
}

.ffs60 {
  fill: url(#ffg61);
}

/* female Back */

.fbs0 {
  fill: url(#fbg1);
}

.fbs1 {
  fill: url(#fbg2);
}

.fbs2 {
  fill: url(#fbg3);
}

.fbs3 {
  fill: url(#fbg4);
}

.fbs4 {
  fill: url(#fbg5);
}

.fbs5 {
  fill: url(#fbg6);
}

.fbs6 {
  fill: url(#fbg7);
}

.fbs7 {
  fill: url(#fbg8);
}

.fbs8 {
  fill: url(#fbg9);
}

.fbs9 {
  fill: url(#fbg10);
}

.fbs10 {
  fill: url(#fbg11);
}

.fbs11 {
  fill: url(#fbg12);
}

.fbs12 {
  fill: url(#fbg13);
}

.fbs13 {
  fill: url(#fbg14);
}

.fbs14 {
  fill: url(#fbg15);
}

.fbs15 {
  fill: url(#fbg16);
}

.fbs16 {
  fill: url(#fbg17);
}

.fbs17 {
  fill: url(#fbg18);
}

.fbs18 {
  fill: url(#fbg19);
}

.fbs19 {
  fill: url(#fbg20);
}

.fbs20 {
  fill: url(#fbg21);
}

.fbs21 {
  fill: url(#fbg22);
}

.fbs22 {
  fill: url(#fbg23);
}

.fbs23 {
  fill: url(#fbg24);
}

.fbs24 {
  fill: url(#fbg25);
}

.fbs25 {
  fill: url(#fbg26);
}

.fbs26 {
  fill: url(#fbg27);
}

.fbs27 {
  fill: url(#fbg28);
}

.fbs28 {
  fill: url(#fbg29);
}

.fbs29 {
  fill: url(#fbg30);
}

.fbs30 {
  fill: url(#fbg31);
}

.fbs31 {
  fill: url(#fbg32);
}

.fbs32 {
  fill: url(#fbg33);
}

.fbs33 {
  fill: url(#fbg34);
}

.fbs34 {
  fill: url(#fbg35);
}

.fbs35 {
  fill: url(#fbg36);
}

.fbs36 {
  fill: url(#fbg37);
}

.fbs37 {
  fill: url(#fbg38);
}

.fbs38 {
  fill: url(#fbg39);
}

.fbs39 {
  fill: url(#fbg40);
}

.fbs40 {
  fill: url(#fbg41);
}

.fbs41 {
  fill: url(#fbg42);
}

.fbs42 {
  fill: url(#fbg43);
}

.fbs43 {
  fill: url(#fbg44);
}

.fbs44 {
  fill: url(#fbg45);
}

.fbs45 {
  fill: url(#fbg46);
}

.fbs46 {
  fill: url(#fbg47);
}

.fbs47 {
  fill: url(#fbg48);
}

.fbs48 {
  fill: url(#fbg49);
}

.fbs49 {
  fill: url(#fbg50);
}

.fbs50 {
  fill: url(#fbg51);
}

.fbs51 {
  fill: url(#fbg52);
}

.fbs52 {
  fill: url(#fbg53);
}

.fbs53 {
  fill: url(#fbg54);
}

.fbs54 {
  fill: url(#fbg55);
}

.fbs55 {
  fill: url(#fbg56);
}

.fbs56 {
  fill: url(#fbg57);
}

.fbs57 {
  fill: url(#fbg58);
}

.fbs58 {
  fill: url(#fbg59);
}

.fbs59 {
  fill: url(#fbg60);
}

.fbs60 {
  fill: url(#fbg61);
}

.fbs61 {
  fill: url(#fbg62);
}

.fbs62 {
  fill: url(#fbg63);
}

.fbs63 {
  fill: url(#fbg64);
}

.fbs64 {
  fill: url(#fbg65);
}

.fbs65 {
  fill: url(#fbg66);
}

.fbs66 {
  fill: url(#fbg67);
}

.fbs67 {
  fill: url(#fbg68);
}

.fbs68 {
  fill: url(#fbg69);
}

.fbs69 {
  fill: url(#fbg70);
}

.fbs70 {
  fill: url(#fbg71);
}

.fbs71 {
  fill: url(#fbg72);
}

.fbs72 {
  fill: url(#fbg73);
}

.fbs73 {
  fill: url(#fbg74);
}

.fbs74 {
  fill: url(#fbg75);
}

.fbs75 {
  fill: url(#fbg76);
}

.fbs76 {
  fill: url(#fbg77);
}

.fbs77 {
  fill: url(#fbg78);
}

.fbs78 {
  fill: url(#fbg79);
}

.fbs79 {
  fill: url(#fbg80);
}

.fbs80 {
  fill: url(#fbg81);
}

.fbs81 {
  fill: url(#fbg82);
}

.fbs82 {
  fill: url(#fbg83);
}

.fbs83 {
  fill: url(#fbg84);
}

.fbs84 {
  fill: url(#fbg85);
}

.fbs85 {
  fill: url(#fbg86);
}

.fbs86 {
  fill: url(#fbg87);
}

.fbs87 {
  fill: url(#fbg88);
}

.fbs88 {
  fill: url(#fbg89);
}

.fbs89 {
  fill: url(#fbg90);
}

.fbs90 {
  fill: url(#fbg91);
}

.fbs91 {
  fill: url(#fbg92);
}

.fbs92 {
  fill: url(#fbg93);
}

.fbs93 {
  fill: url(#fbg94);
}

.fbs94 {
  fill: url(#fbg95);
}

.fbs95 {
  fill: url(#fbg96);
}

.fbs96 {
  fill: url(#fbg97);
}

.fbs97 {
  fill: url(#fbg98);
}

.fbs98 {
  fill: url(#fbg99);
}

.fbs99 {
  fill: url(#fbg100);
}

.fbs100 {
  fill: url(#fbg101);
}

.fbs101 {
  fill: url(#fbg102);
}

.fbs102 {
  fill: url(#fbg103);
}

.fbs103 {
  fill: url(#fbg104);
}

.fbs104 {
  fill: url(#fbg105);
}

.fbs105 {
  fill: url(#fbg106);
}

.fbs106 {
  fill: url(#fbg107);
}

.fbs107 {
  fill: url(#fbg108);
}

.fbs108 {
  fill: url(#fbg109);
}

.fbs109 {
  fill: url(#fbg110);
}

.fbs110 {
  fill: url(#fbg111);
}

.fbs111 {
  fill: url(#fbg112);
}

.fbs112 {
  fill: url(#fbg113);
}

.fbs113 {
  fill: url(#fbg114);
}

.fbs114 {
  fill: url(#fbg115);
}

.fbs115 {
  fill: url(#fbg116);
}

.fbs116 {
  fill: url(#fbg117);
}

.nav-link {
  font-weight: 700 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-logout .btn-primary {
  background-color: transparent !important;
  border: none !important;
}

.navbar-nav .dropdown-menu {
  position: none !important;
}

.btn-group>.btn {
  position: relative;
  flex: none !important;
}

.logo-image {
  max-height: 90px;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo-image {
    max-height: 65px !important;
  }
}

/* Abbas Styles */
.googplay-hover-green:hover {
  color: #48ff48 !important;
  opacity: 1 !important;
}

.apple-hover-black:hover {
  color: black !important;
  opacity: 1 !important;
}

.youtube-hover-red:hover {
  color: #fb3958 !important;
  opacity: 1 !important;
}

.insta-hover-light-red:hover {
  color: #fb3958 !important;
  opacity: 1 !important;
}

.fb-hover-blue:hover {
  color: #00acee !important;
  opacity: 1 !important;
}

.tiktok-hover-light-blue:hover {
  color: #69C9D0 !important;
  opacity: 1 !important;
}

/* .overflowY-scroll {
  overflow-y: scroll;
  height: 700px;
} */



@media only screen and (max-width: 600px) {


  .overflowY-scroll {
    overflow-y: inherit;
    height: inherit;
  }

  .scroll-top {
    width: 30px;
  }

}

@media only screen and (max-width: 767px) {

  .scroll-to-top-btn.visible {
    display: none !important;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 60px;
}



/* Abbas styles End */

/* ScrollToTopButton.css */
.scroll-to-top-btn {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 99999999999999999;
}

.scroll-to-top-btn.visible {
  display: block;
}